.registrationForm .checkbox-lg .ant-checkbox-inner 
{
    width: 32px !important;
    height: 32px !important;
    border-radius: 5px;
    border: 3px solid lightgray;
}

.registrationForm .back-red .ant-checkbox-inner 
{
    background-color: #ef4444;
    color: white;
}

.registrationForm .checkbox-lg .ant-checkbox-input
{
    width: 32px !important;
    height: 32px !important;
    border-radius: 5px;
    border: 3px solid lightgray;
}

.registrationForm .checkbox-lg .ant-checkbox-checked .ant-checkbox-inner::after
{
    opacity: 1;
    transform: rotate(45deg) scale(1) translate(-50%,-50%);
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    width: 8.714286px;
    height: 17.142857px;
    top: 46%;
}

.registrationForm .checkbox-lg .ant-checkbox-wrapper 
{
    line-height: 35px;
}

.registrationForm .ant-select-selector
{
    font-size: large;
    height: 38px !important
}

@media (min-width: 768px) {
    .table-overflow {
        height: calc(100vh - 160px);
        overflow-y: auto;
    }
}

@media (max-width: 767px) {
    .table-overflow {
        height: calc(100vh - 240px);
        overflow-y: auto;
    }
}
